var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "competition h-295px my-2",
      class: _vm.data.block,
      attrs: { id: "competition-type4" }
    },
    [
      _c(
        "swiper",
        {
          ref: "competitionTab",
          staticClass: "tabSwiper h-44px",
          attrs: { options: _vm.tabSwiperOption },
          on: { slideChange: _vm.onSlide }
        },
        [
          _vm._l(_vm.filterCompetitionDate, function(competitionDate, index) {
            return _c("swiper-slide", { key: index }, [
              _c(
                "div",
                {
                  staticClass:
                    "cursor-pointer d-flex rounded align-center justify-end h-100"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "caption icon--text w-100 px-4 d-flex justify-center align-center font-weight-bold"
                    },
                    [
                      _c("Icon", {
                        staticClass: "icon--text mr-2",
                        attrs: {
                          data: require("@icon/date.svg"),
                          width: "16",
                          height: "16"
                        }
                      }),
                      _vm._v(" " + _vm._s(competitionDate) + " ")
                    ],
                    1
                  )
                ]
              )
            ])
          }),
          _c(
            "div",
            {
              staticClass: "swiper-button-prev",
              attrs: { slot: "button-prev" },
              on: { click: _vm.slidePrev },
              slot: "button-prev"
            },
            [
              _c(
                "v-icon",
                { staticClass: "icon--text", attrs: { medium: "" } },
                [_vm._v(" mdi-chevron-left ")]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "swiper-button-next",
              attrs: { slot: "button-next" },
              on: { click: _vm.sliderNext },
              slot: "button-next"
            },
            [
              _c(
                "v-icon",
                { staticClass: "icon--text", attrs: { medium: "" } },
                [_vm._v(" mdi-chevron-right ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        [
          _c(
            "swiper",
            {
              ref: "competitionItem",
              staticClass: "swiper h-247px rounded",
              attrs: { options: _vm.competitionSwiperOption },
              on: { slideChange: _vm.competitionItemOnSlide }
            },
            _vm._l(_vm.filterCompetitionItem, function(competition) {
              return _c(
                "swiper-slide",
                { key: competition._id, staticClass: "overflow-y-auto" },
                _vm._l(competition, function(item) {
                  return _c(
                    "div",
                    {
                      key: item._id,
                      staticClass:
                        "card1 my-2 rounded h-125px pt-2 box-shadow mx-4"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex pl-3 caption font-weight-bold primary--text"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "logo mr-2" },
                            [
                              _c("v-img", {
                                attrs: {
                                  "max-width": "20",
                                  "max-height": "20",
                                  src: item.game_logo
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" " + _vm._s(item.competition_name) + " ")
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "competion__content d-flex justify-space-between align-center px-4 h-85px"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "competion__content-home w-30 d-flex flex-column align-center text-center"
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  "max-width": "32",
                                  "max-height": "32",
                                  src: item.home.team_logo
                                }
                              }),
                              _c(
                                "span",
                                { staticClass: "caption title--text mt-2" },
                                [_vm._v(_vm._s(item.home.team_name))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "competion__content-num warning--text font-weight-bold text-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "subtitle-1 secondary--text font-weight-medium"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.start_datetime
                                          .split(" ")[1]
                                          .slice(0, 5)
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "competion__content-away w-30 d-flex justify-center flex-column text-center align-center"
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  "max-width": "32",
                                  "max-height": "32",
                                  src: item.away.team_logo
                                }
                              }),
                              _c(
                                "span",
                                { staticClass: "caption title--text mt-2" },
                                [_vm._v(_vm._s(item.away.team_name))]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }